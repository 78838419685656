

export default {
    name: 'ControlMixins',
    methods: {
        replicateControls(obj) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/assets/${obj.assetId}/risks/${obj.riskId}/replicate-by-category`,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },
    }
}