<template>
  <b-overlay
    opacity="0.17"
    blur="1rem"
    :show="showOverlay"
    rounded="md"
    variant="secondary"
  >
    <div class="card">
      <div class="card-header border-bottom d-flex">
        <div
          style="width: 70%"
          class="d-flex flex-column justify-content-center align-items-start"
        >
          <h2 class="font-weight-bolder">
            <span class="align-middle">Asset Risk Details</span>
          </h2>
        </div>

        <div class="d-flex justify-content-end align-items-center">
          <b-button
            size="sm"
            @click="closeSidebar()"
            variant="outline-secondary"
            ><feather-icon size="24" icon="XIcon"
          /></b-button>
        </div>
      </div>

      <div class="card-body pt-3">
        <div
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            mb-2
          "
        >
          <p class="mb-25 font-weight-bolder">Name</p>
          <h4 class="mb-0 font-weight-bolder text-primary">
            {{ data.risk.name }}
          </h4>
        </div>

        <div
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            mb-2
          "
          v-if="data.risk_statement"
        >
          <p class="mb-25 font-weight-bolder">Statement</p>
          <p class="mb-0 font-weight-regular" style="font-size: 16px">
            {{ data.risk_statement }}
          </p>
        </div>

        <div
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            mb-2
          "
          v-if="data.threat && data.threat !== null"
        >
          <p class="mb-25 font-weight-bolder">Threat</p>
          <p class="mb-0 font-weight-regular" style="font-size: 16px">
            {{ data.threat.name }}
          </p>
        </div>

        <div
          class="
            d-flex
            flex-column
            align-items-start
            justify-content-start
            mb-3
          "
          v-if="data.vulnerability && data.vulnerability !== null"
        >
          <p class="mb-25 font-weight-bolder">Vulnerability</p>
          <p class="mb-0 font-weight-regular" style="font-size: 16px">
            {{ data.vulnerability.name }}
          </p>
        </div>

        <div class="d-flex flex-row align-items-center justify-content-around mb-3 border p-1">
          <progress-bar :value="data.impact_value" :max="100" :min="1" title="Impact Value" />

          <progress-bar :value="data.residual_impact_value" :max="100" :min="1" title="Residual Impact" />

          <progress-bar :value="data.risk_value" :max="100" :min="1" title="Risk Value" />

          <progress-bar :value="data.residual_risk_value" :max="100" :min="1" title="Residual Risk" />
        </div>
      </div>
    </div>
  </b-overlay>
</template>

<script>
import {
  BOverlay,
  BButton,
  BFormGroup,
  BFormInput,
  BForm,
  BBadge,
  BFormSelect,
  BFormTextarea,
} from "bootstrap-vue";
import ProgressBar from "../../../components/ProgressBar.vue";

export default {
  components: {
    BOverlay,
    BButton,
    BFormGroup,
    BFormInput,
    BForm,
    BBadge,
    BFormSelect,
    BFormTextarea,
    ProgressBar,
  },
  data() {
    return {
      showOverlay: false,
    };
  },

  mixins: [],

  props: {
    closeSidebar: {
      required: true,
      type: Function,
    },

    data: {
      required: true,
      type: Object,
    },
  },

  watch: {},

  mounted() {},

  methods: {},
};
</script>

<style>
</style>