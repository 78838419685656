<template>
  <div class="card">
    <div class="card-header bg-light-secondary">
      <h4 class="font-weight-bolder" style="width: 70%">{{ risk.risk.name }}</h4>
      <div class="d-flex justify-content-end align-items-center">
        <b-button @click="closeSidebar()" variant="outline-secondary" size="sm"
          ><feather-icon size="24" icon="XIcon"
        /></b-button>
      </div>
    </div>
    <b-overlay
      opacity="0.17"
      blur="1rem"
      :show="showOverlay"
      rounded="md"
      variant="secondary"
    >
      <div class="card-body mt-2">
        <!-- <div class="row">
        <div class="col-6 d-flex justify-content-center align-items-center">
          <progress-bar
            title="Impact Value"
            :value="risk.impact_value"
            :min="1"
            :max="25"
          />
        </div>
        <div class="col-6 d-flex justify-content-center align-items-center">
          <progress-bar
            title="Risk Value"
            :value="risk.risk_value"
            :min="1"
            :max="125"
          />
        </div>
      </div> -->

        <!-- <div class="my-1 row d-flex justify-content-center align-items-center">
        <vue-apex-charts
          type="radialBar"
          height="200"
          :options="chartOptions"
          :series="[percentage(risk.risk_value, 125)]"
        />
      </div> -->

        <!-- <div class="row mt-3">
        <div class="col-6 d-flex justify-content-center align-items-center">
          <progress-bar
            title="Residual Impact Value"
            :value="risk.residual_impact_value"
            :min="1"
            :max="25"
          />
        </div>
        <div class="col-6 d-flex justify-content-center align-items-center">
          <progress-bar
            title="Residual Risk Value"
            :value="risk.residual_risk_value"
            :min="1"
            :max="125"
          />
        </div>
      </div> -->

        <div class="mt-3 table-responsive">
          <table role="table" class="table b-table">
            <thead role="rowgroup" class="thead-dark">
              <tr role="row">
                <th role="columnheader" scope="col" class="text-center header">
                  Threat Value
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Vulnerability Value
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Likelihood Value
                </th>
              </tr>
            </thead>

            <tbody role="rowgroup">
              <tr class="b-table-has-details cursor-pointer" role="row">
                <td aria-colindex="1" role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <score-input
                      :id="risk._id"
                      :value="Number(risk.threat_value)"
                      :increment="incrementThreatValue"
                      :decrement="decrementThreatValue"
                    />
                  </div>
                </td>
                <td aria-colindex="1" role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <score-input
                      :id="risk._id"
                      :value="Number(risk.vulnerability_value)"
                      :increment="incrementVulnerabilityValue"
                      :decrement="decrementVulnerabilityValue"
                    />
                  </div>
                </td>
                <td aria-colindex="1" role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <b-form-select
                      style="width: 170px"
                      class="font-weight-bold"
                      size="sm"
                      v-model="risk.likelihood_value"
                      v-on:change="handleLikelihoodValueChange(risk._id)"
                      :options="likelihoodOptions"
                    ></b-form-select>
                  </div>
                </td>
              </tr>
              <tr tabindex="-1" role="row" class="b-table-details">
                <td role="cell" colspan="12">
                  <div class="card bg-light-secondary">
                    <div class="card-body">
                      <div class="row">
                        <div
                          class="
                            col-6
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                        >
                          <progress-bar
                            title="Impact Value"
                            :value="risk.impact_value"
                            :min="1"
                            :max="100"
                          />
                        </div>
                        <div
                          class="
                            col-6
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                        >
                          <progress-bar
                            title="Risk Value"
                            :value="risk.risk_value"
                            :min="1"
                            :max="100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div class="mt-3 table-responsive">
          <table role="table" class="table b-table">
            <thead role="rowgroup" class="thead-dark">
              <tr role="row">
                <th role="columnheader" scope="col" class="text-center header">
                  Residual Threat Value
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Residual Vulnerability Value
                </th>
                <th role="columnheader" scope="col" class="text-center header">
                  Residual Likelihood Value
                </th>
              </tr>
            </thead>

            <tbody role="rowgroup">
              <tr class="b-table-has-details cursor-pointer" role="row">
                <td aria-colindex="1" role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <score-input
                      :id="risk._id"
                      :value="Number(risk.residual_threat_value)"
                      :increment="incrementResidualThreatValue"
                      :decrement="decrementResidualThreatValue"
                    />
                  </div>
                </td>
                <td aria-colindex="1" role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <score-input
                      :id="risk._id"
                      :value="Number(risk.residual_vulnerability_value)"
                      :increment="incrementResidualVulnerabilityValue"
                      :decrement="decrementResidualVulnerabilityValue"
                    />
                  </div>
                </td>
                <td aria-colindex="1" role="cell">
                  <div class="d-flex justify-content-center align-items-center">
                    <b-form-select
                      style="width: 170px"
                      class="font-weight-bold"
                      size="sm"
                      v-model="risk.residual_likelihood_value"
                      v-on:change="
                        handleResidualLikelihoodValueChange(risk._id)
                      "
                      :options="likelihoodOptions"
                    ></b-form-select>
                  </div>
                </td>
              </tr>
              <tr tabindex="-1" role="row" class="b-table-details">
                <td role="cell" colspan="12">
                  <div class="card bg-light-secondary">
                    <div class="card-body">
                      <div class="row mt-3">
                        <div
                          class="
                            col-6
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                        >
                          <progress-bar
                            title="Residual Impact Value"
                            :value="risk.residual_impact_value"
                            :min="1"
                            :max="100"
                          />
                        </div>
                        <div
                          class="
                            col-6
                            d-flex
                            justify-content-center
                            align-items-center
                          "
                        >
                          <progress-bar
                            title="Residual Risk Value"
                            :value="risk.residual_risk_value"
                            :min="1"
                            :max="100"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import { BFormSelect, BCard, BButton, BOverlay } from "bootstrap-vue";
import ScoreInput from "@/components/ScoreInput.vue";
import ProgressPercent from "../../../components/ProgressPercent.vue";
import ProgressBar from "../../../components/ProgressBar.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "AssetRisksAnalysisForm",
  components: {
    BFormSelect,
    BCard,
    ScoreInput,
    ProgressPercent,
    ProgressBar,
    BButton,
    VueApexCharts,
    BOverlay,
  },
  props: {
    showOverlay: {
      type: Boolean,
      required: true,
    },
    risk: {
      type: Object,
      required: true,
    },
    closeSidebar: {
      type: Function,
      required: true,
    },
    incrementThreatValue: {
      type: Function,
      required: true,
    },
    decrementThreatValue: {
      type: Function,
      required: true,
    },
    incrementVulnerabilityValue: {
      type: Function,
      required: true,
    },
    decrementVulnerabilityValue: {
      type: Function,
      required: true,
    },
    incrementResidualThreatValue: {
      type: Function,
      required: true,
    },
    decrementResidualThreatValue: {
      type: Function,
      required: true,
    },
    incrementResidualVulnerabilityValue: {
      type: Function,
      required: true,
    },
    decrementResidualVulnerabilityValue: {
      type: Function,
      required: true,
    },
    handleLikelihoodValueChange: {
      type: Function,
      required: true,
    },
    handleResidualLikelihoodValueChange: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      likelihoodOptions: [
        { value: null, text: "Select a value.." },
        { value: 1, text: "Once 5 years" },
        { value: 2, text: "Once 2 years" },
        { value: 3, text: "Once a year" },
        { value: 4, text: "Once in Six Months" },
        { value: 5, text: "Once Month" },
      ],
      series: [90],
      chartOptions: {
        chart: {
          height: 350,
          type: "radialBar",
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "70%",
            },
          },
        },
        labels: ["Overall Score"],
      },
    };
  },
  methods: {
    percentage(partialValue, totalValue) {
      return (100 * partialValue) / totalValue;
    },
  },
};
</script>

<style scoped>
td {
  width: 200px;
}
</style>