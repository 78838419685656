



export default {
    name: 'RiskMixins',
    methods: {
        updateRiskImpactAndLikelihoodRelation(
            impactValue,
            likelihoodValue,
            riskId,
            assetId
        ) {
            return new Promise((resolve, reject) => {
                var params = new URLSearchParams();
                if (impactValue) {
                    params.append("impact_value", impactValue);
                }
                else {
                    params.append("impact_value", 1);
                }
                if (likelihoodValue) {
                    params.append("likelihood_value", likelihoodValue);
                }
                else {
                    params.append("likelihood_value", 1);
                }
                const options = {
                    method: "PUT",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}/update`,
                    data: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        updateResidualRiskScore(
            impactValue,
            likelihoodValue,
            residualRiskValue,
            riskId,
            assetId
        ) {
            return new Promise((resolve, reject) => {
                var params = new URLSearchParams();
                if (impactValue) {
                    params.append("impact_value", impactValue);
                }
                else {
                    params.append("impact_value", 1);
                }
                if (likelihoodValue) {
                    params.append("likelihood_value", likelihoodValue);
                }
                else {
                    params.append("likelihood_value", 1);
                }
                if (residualRiskValue) {
                    params.append("residual_risk_value", residualRiskValue);
                }
                else {
                    params.append("residual_risk_value", 1);
                }
                const options = {
                    method: "PUT",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}/update`,
                    data: params,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        getAssetRiskRelation(assetId, riskId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/${riskId}`,
                };

                this.$http(options)
                    .then(res => {
                        resolve(res)
                    })
                    .catch(err => {
                        reject(err)
                    })
            })
        },
        getAllRisksByPage(pageNum, params) {
            return new Promise((resolve, reject) => {
                if ((pageNum !== null) | (pageNum !== 0)) {
                    const options = {
                        method: "GET",
                        url: `${process.env.VUE_APP_BASEURL}/risks/register`,
                        params: {
                            page: pageNum,
                            ...params
                        },
                    };
                    this.$http(options)
                        .then(resp => { resolve(resp) })
                        .catch(err => { reject(err) })
                }
            })
        },
        getAllRisksBySearchPhrase(searchPhrase) {
            return new Promise((resolve, reject) => {
                if ((searchPhrase !== null) | (searchPhrase !== '')) {
                    const options = {
                        method: "GET",
                        url: `${process.env.VUE_APP_BASEURL}/risks`,
                        params: {
                            search: searchPhrase,
                        }
                    };
                    this.$http(options)
                        .then(resp => { resolve(resp) })
                        .catch(err => { reject(err) })
                }
            })
        },
        getRiskDetails(id) {
            return new Promise((resolve, reject) => {
                if (id !== null) {
                    const options = {
                        method: "GET",
                        url: `${process.env.VUE_APP_BASEURL}/risks/${id}`,
                    };
                    this.$http(options)
                        .then(resp => { resolve(resp) })
                        .catch(err => { reject(err) })
                }
            })
        },
        createNewCustomRisk(
            assetId,
            risk,
            threat,
            vulnerabilities

        ) {
            return new Promise((resolve, reject) => {
                console.log('Risk', risk);
                console.log('threat', threat);
                console.log('vulnerabilities', vulnerabilities);
                const bodyFormData = new FormData();
                bodyFormData.append(`risk[is_new]`, `${risk.is_new ? 1 : 0}`);
                if (risk.is_new === true) {
                    bodyFormData.append(`risk[risk_name]`, `${risk.risk_name}`);
                }
                if (risk.is_new === false) {
                    bodyFormData.append(`risk[risk_id]`, `${risk.risk_id ? risk.risk_id : ''}`)

                }

                if(threat.is_new!==null){
                    bodyFormData.append(`threat[is_new]`, `${threat.is_new ? 1 : 0}`)
                }

                if(threat.threat_name){
                    bodyFormData.append(`threat[threat_name]`, threat.threat_name)
                }

                if(!threat.is_new || threat.is_new==false && threat.threat_id){
                    bodyFormData.append(`threat[threat_id]`, threat.threat_id)
                }

                // threats.map((threat, i) => {
                //     bodyFormData.append(`threat[${i}][is_new]`, `${threat.is_new ? 1 : 0}`)
                //     if (threat.is_new === true) {
                //         bodyFormData.append(`threats[${i}][threat_name]`, `${threat.threat_name}`)
                //     }
                //     bodyFormData.append(`threats[${i}][threat_id]`, `${threat.threat_id ? threat.threat_id : ''}`)
                // })

                vulnerabilities.map((vul, i) => {
                    bodyFormData.append(`vulnerabilities[${i}][is_new]`, `${vul.is_new ? 1 : 0}`)
                    if (vul.is_new === true) {
                        bodyFormData.append(`vulnerabilities[${i}][vulnerability_name]`, `${vul.vulnerability_name}`)
                    }
                    bodyFormData.append(`vulnerabilities[${i}][vulnerability_id]`, `${vul.vulnerability_id ? vul.vulnerability_id : ''}`)
                })
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/custom-map`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then((res) => {
                        resolve(res);
                    })
                    .catch((err) => {
                        reject(err);
                    });
            });
        },
        createRiskAction(obj) {
            return new Promise((resolve, reject) => {

                const riskId = obj.risk_id
                const bodyFormData = new FormData();
                bodyFormData.append("note", obj.note);

                bodyFormData.append("type", obj.type);
                if ([2,1,4].includes(obj.type)) {
                    bodyFormData.append("task_title", obj.mitigate.taskTitle);
                    bodyFormData.append("task_assignee", obj.mitigate.taskAssignee.user_id);
                    bodyFormData.append("task_due_date", obj.mitigate.taskDueDate);
                    bodyFormData.append("task_priority", obj.mitigate.taskPriority);
                    bodyFormData.append("task_description", obj.mitigate.taskDescription);
                    bodyFormData.append("risk_register_id", riskId);
                }
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/risks/register/${riskId}/actions`,
                    data: bodyFormData,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })


        },
        createBulkRiskAction(obj) {
            return new Promise((resolve, reject) => {
                const bodyFormData = new FormData();
                if (obj.type !== 4) {
                    bodyFormData.append("note", obj.note);
                }
                bodyFormData.append("type", obj.type);
                if (obj.type === 4) {
                    bodyFormData.append("task_title", obj.mitigate.taskTitle);
                    bodyFormData.append("task_assignee", obj.mitigate.taskAssignee.user_id);
                    bodyFormData.append("task_due_date", obj.mitigate.taskDueDate);
                    bodyFormData.append("task_priority", obj.mitigate.taskPriority);
                    bodyFormData.append("task_description", obj.mitigate.taskDescription);
                }

                if (obj.risk_cat_scores.length > 0) {
                    obj.risk_cat_scores.map((id, i) => {
                        const data = id.split('_');
                        bodyFormData.append(`risks[${i}][risk_id]`, data[0]);
                        bodyFormData.append(`risks[${i}][asset_category_id]`, data[1]);
                        bodyFormData.append(`risks[${i}][risk_value]`, data[2]);
                    })


                    const options = {
                        method: "POST",
                        headers: { "content-type": "application/x-www-form-urlencoded" },
                        url: `${process.env.VUE_APP_BASEURL}/risks/action`,
                        data: bodyFormData,
                    };
                    this.$http(options)
                        .then(res => resolve(res))
                        .catch(err => reject(err))
                }
                else {
                    if (obj.selectedParams.search) {
                        bodyFormData.append("risk_search[search]", obj.selectedParams.search);
                    }
                    if (obj.selectedParams.max_asset_risk_value) {
                        bodyFormData.append("risk_search[max_risk_value]", obj.selectedParams.max_asset_risk_value);
                    }
                    if (obj.selectedParams.max_asset_risk_residual_score) {
                        bodyFormData.append("risk_search[max_residual_risk_value]", obj.selectedParams.max_asset_risk_residual_score);
                    }

                    const options = {
                        method: "POST",
                        headers: { "content-type": "application/x-www-form-urlencoded" },
                        url: `${process.env.VUE_APP_BASEURL}/risks/action`,
                        data: bodyFormData,
                    };
                    this.$http(options)
                        .then(res => resolve(res))
                        .catch(err => reject(err))
                }

            })


        },

        getRiskActionTasks(user_id) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "GET",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/risks/action/tasks`,
                    params: {
                        user_id: user_id,
                    }
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },

        repopulateRisks(assetId) {
            return new Promise((resolve, reject) => {
                const options = {
                    method: "POST",
                    headers: { "content-type": "application/x-www-form-urlencoded" },
                    url: `${process.env.VUE_APP_BASEURL}/assets/${assetId}/risks/repopulate`,
                };
                this.$http(options)
                    .then(res => resolve(res))
                    .catch(err => reject(err))
            })
        },

        //----------------Threats-------------------------

        getAllThreatsBySearchPhrase(searchPhrase) {
            return new Promise((resolve, reject) => {
                if ((searchPhrase !== null) | (searchPhrase !== '')) {
                    const options = {
                        method: "GET",
                        url: `${process.env.VUE_APP_BASEURL}/threats`,
                        params: {
                            search: searchPhrase,
                        }
                    };
                    this.$http(options)
                        .then(resp => { resolve(resp) })
                        .catch(err => { reject(err) })
                }
            })
        },


        //----------------Vulnerabilities-------------------------
        getAllVulnerabilitiesBySearchPhrase(searchPhrase) {
            return new Promise((resolve, reject) => {
                if ((searchPhrase !== null) | (searchPhrase !== '')) {
                    const options = {
                        method: "GET",
                        url: `${process.env.VUE_APP_BASEURL}/vulnerabilities`,
                        params: {
                            search: searchPhrase,
                        }
                    };
                    this.$http(options)
                        .then(resp => { resolve(resp) })
                        .catch(err => { reject(err) })
                }
            })
        },
    },
}